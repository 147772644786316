"use client";

import { adobePVBeacon, Adobe } from "@yahoo-creators/i13n";
import { useDeviceSize } from "@yahoo-news/util";
import { useEffect, useState } from "react";
import {
  useCurrentPath,
  useIsModal,
  useIsClientSideNavigation,
} from "@/contexts/PathProvider";
import { useRequestContext } from "@/lib/request/client";

export const AdobeConfig = () => {
  const path = useCurrentPath();
  const [lastPageViewPath, setLastPageViewPath] = useState(path);
  const isModal = useIsModal();
  const deviceSize = useDeviceSize();
  const isClientNav = useIsClientSideNavigation();
  const { site } = useRequestContext();

  // page view beacons for new article view / seamless articles
  useEffect(() => {
    const currentPath = window.location.pathname;
    let prop53 = "";
    if (isClientNav && isModal) {
      prop53 = "Modal";
    } else if (isClientNav && !isModal) {
      prop53 = "Modal close";
    }
    if (window.s && currentPath === path && lastPageViewPath !== currentPath) {
      adobePVBeacon(prop53);
      setLastPageViewPath(currentPath);
    }
  }, [lastPageViewPath, path, isModal, isClientNav]);

  const config = {
    device: deviceSize,
    isModal: isModal,
    site: site,
  };

  return <Adobe config={config} />;
};
