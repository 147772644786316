"use client";

import { type Config } from "@vzmi/types-rapid";
import {
  Rapid,
  addContentProgression,
  beaconClick,
  beaconPageview,
  endContentProgression,
  reInit,
} from "@yahoo-creators/i13n";
import { useDeviceSize } from "@yahoo-news/util";
import { type FC, useEffect, useMemo, useState } from "react";
import {
  BRAND_PAGE_PT,
  CREATOR_DIRECTORY_PT,
  CREATOR_HUB_PT,
  CREATOR_PAGES_REGEX,
} from "@/app/creators/config";
import {
  useCurrentPath,
  useIsClientSideNavigation,
  useIsModal,
  usePreviousPath,
} from "@/contexts/PathProvider";
import { useSeamlessSlugChange } from "@/hooks/events";
import { getArticleId, getClickThroughUrl } from "@/lib/article/util";
import {
  getRapidConfigForArticle,
  getRapidConfigForCreator,
  getRapidConfigForSitemap,
} from "@/lib/i13n";
import { useRequestContext } from "@/lib/request/client";
import { type CaasArticle } from "@/types/Caas";
const SITEMAP_PAGES_REGEX = new RegExp("/sitemap(?:/([\\w-]*))?");

interface Props {
  article?: CaasArticle;
  articleIndex?: number;
}

export const RapidConfig: FC<Props> = ({
  article: initialArticle,
  articleIndex: initialArticleIndex,
}) => {
  const requestContext = useRequestContext();
  const isClientNav = useIsClientSideNavigation();
  const path = useCurrentPath();
  const previousPath = usePreviousPath();
  const [lastPageViewPath, setLastPageViewPath] = useState(path);
  const isModal = useIsModal();
  const slugState = useSeamlessSlugChange(
    initialArticle && initialArticleIndex !== undefined
      ? {
          article: initialArticle,
          index: initialArticleIndex || 0,
          slug: new URL(getClickThroughUrl(initialArticle)).toString(),
        }
      : null,
  );
  const deviceSize = useDeviceSize();

  const [requestID, setRequestID] = useState("");
  const [hasEditorialReadMore, setHasEditorialReadMore] = useState(false);
  const [isSeedArticleShoppable, setSeedArticleShoppable] = useState(false);

  useEffect(() => {
    const randomID = Math.random().toString(36).substring(2, 15) + Date.now();
    setRequestID(randomID);
  }, []);

  const config = useMemo((): Config | undefined => {
    let config;
    if (!deviceSize) return;
    if (!requestID) return;

    const referrerUrl = previousPath
      ? window.location.host + previousPath
      : document.referrer;

    if (slugState?.article && typeof slugState.index === "number") {
      // the seed article
      if (slugState.index === 0) {
        const { readMoreList, isReadMoreListOverridden } =
          slugState.article.data.contentMeta.contentIdentifiers;
        if (readMoreList && !isReadMoreListOverridden)
          setHasEditorialReadMore(true);
        if (slugState.article.data.contentMeta.isShoppable)
          setSeedArticleShoppable(true);
      }

      config = getRapidConfigForArticle({
        article: slugState.article,
        articleIndex: slugState.index,
        deviceSize,
        hasEditorialReadMore,
        isClientNav,
        isModal,
        isPerpetualPost: previousPath?.endsWith(".html"),
        isSeedArticleShoppable,
        referrerUrl,
        requestContext,
        requestID,
      });
    } else if (path && SITEMAP_PAGES_REGEX.test(path)) {
      config = getRapidConfigForSitemap({
        deviceSize,
        referrerUrl,
        requestContext,
      });
    } else {
      const creatorSlug = path?.match(CREATOR_PAGES_REGEX)?.[1] || "";
      const isBrandPage = !!creatorSlug;
      const isDirectory = path && path.includes("directory");
      const pageType = () => {
        if (isDirectory) {
          return CREATOR_DIRECTORY_PT;
        } else if (isBrandPage) {
          return BRAND_PAGE_PT;
        } else {
          return CREATOR_HUB_PT;
        }
      };
      const pageContentType = isDirectory ? "info" : "creator";
      config = getRapidConfigForCreator({
        creatorSlug,
        deviceSize,
        isClientNav,
        pageContentType,
        pageType: pageType(),
        referrerUrl,
        requestContext,
      });
    }
    return config;
  }, [
    deviceSize,
    hasEditorialReadMore,
    isClientNav,
    isModal,
    isSeedArticleShoppable,
    path,
    previousPath,
    requestContext,
    requestID,
    slugState,
  ]);

  // content progression beacons
  useEffect(() => {
    if (slugState?.article) {
      const rapidId = `article-${getArticleId(slugState.article)}`;
      addContentProgression(rapidId);
      return () => {
        endContentProgression(rapidId);
      };
    }
  }, [slugState?.article]);

  // page view beacons
  useEffect(() => {
    const currentPath = window.location.pathname;

    if (
      window.rapidInstance &&
      config &&
      currentPath === path &&
      lastPageViewPath !== currentPath
    ) {
      beaconClick({ options: { dwell: "stop" }, sec: "rapidDwell", slk: "" });
      reInit(config);
      // reInit should have beaconed but it isn't. Manually fire it now.
      beaconPageview();
      setLastPageViewPath(currentPath);
    }
  }, [config, lastPageViewPath, path]);

  return <Rapid config={config} />;
};
